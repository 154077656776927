import {mapState,mapActions} from 'vuex';
import Vue from 'vue';
import { Empty } from 'vant';
import { Popup ,Toast,Dialog} from 'vant';

Vue.use(Empty);
Vue.use(Toast);
Vue.use(Dialog);
export default {
  name: 'claimList',
  components: {},
  data() {
    return {
      active: 0,
      noback:true,
      notFinished:[],
      finished:[]
      // 保单列表


    };
  },
  beforeCreate() {

  },
  created() {
    const token = this.getUrlParam('token');
    if(token !=''&&token !=undefined &&token !=null){
      localStorage.setItem('token',token)
    }
    Toast({
      type:"loading",
      message: '加载中...',
      forbidClick: true,
      duration:0
    });
    this.claimList().then(res=>{
      if(res.code==200){
        Toast.clear()
        this.notFinished=res.data.wwclist;
        this.finished=res.data.ywclist
      }
    })

  },
  methods: {
    ...mapActions('claims', ['claimList']),
    back(){
      this.$router.push({name:'Warranty2'})
    },
    todetail(bxclaimsId){

      this.$router.push({name:'claimDetail',query:{claimsId:bxclaimsId}})
    },
    getUrlParam(name) {
      var reg=new RegExp('(^|&)'+name+'=([^&]*)(&|$)')
      var r=window.location.search.substr(1).match(reg)
      if(r!=null) return unescape(r[2])
      return null
    }










  },
};
